<!--商品入库-->
<template>
    <div class="mainBox">
        <!--Header-->
        <div class="handleBox flex flex-jc-sb flex-ai-c">
            <el-row style="font-size: 18px;color: rgb(149, 161, 185);">新建入库</el-row>
            <el-input placeholder="搜索商品名称" v-model="select.search" size="small" style="width:300px" @change="curr=1;getList()">
                <el-button slot="append" icon="el-icon-search" ></el-button>
            </el-input>
            <div>
                <el-select v-model="select.pid" size="small" style="margin-right:5px" clearable placeholder="商品分类" @change="changeType($event)">
                    <el-option v-for="item in selectData.sortData" :key="item.id" :label="item.cate_name" :value="item.id">
                    </el-option>
                </el-select>
                <el-select v-model="select.cate_id" clearable placeholder="商品子分类" size="small" @change="changeSrot($event)">
                    <el-option-group v-for="group in selectData.zsortData" :key="group.id" :label="group.cate_name">
                        <el-option v-for="item in group.children" :key="item.id" :label="item.cate_name" :value="item.id"></el-option>
                    </el-option-group>
                </el-select>
                <!-- <el-select v-model="select.value1" size="small" clearable placeholder="请选择商品子分类" @change="getRole($event)">
                    <el-option v-for="item in selectData.sortData" :key="item.id" :label="item.cate_name" :value="item.id">
                    </el-option>
                </el-select> -->
            </div>
        </div>
        <!--主要内容-->
        <div class="table" style="height:calc( 100% - 175px );">
            <el-table :data="list" ref="table" :row-key="getRowKeys" :expand-row-keys="expands"  @expand-change="toggleExpansion" @row-click="toggleExpansion"  stripe :header-cell-style="{background:'#fafdff',color:'#606266',fontWeight:'normal'}" @sort-change='getList()' height="calc( 100% - 41px )">
                <el-table-column type="expand">
                    <template slot-scope="props" width="1">
                        <el-form label-position="top" inline class="demo-table-expand enterHouse_expand">
                            <el-form-item label="商品规格：">
                                <p v-for="(item,index) in props.row.productAttrValue" :key="index">{{item.sku}}</p>
                            </el-form-item>
                            <el-form-item label="进价：" style="margin-left:30px">
                                <p v-for="(item,index) in props.row.productAttrValue" :key="index">{{item.cost}}元</p>
                            </el-form-item>
                            <el-form-item label="售价：" style="margin-left:30px">
                                <p v-for="(item,index) in props.row.productAttrValue" :key="index">{{item.price}}元</p>
                            </el-form-item>
                            <el-form-item label="总库存：" style="margin-left:30px">
                                <p v-for="(item,index) in props.row.productAttrValue" :key="index">{{item.total_stock}}</p>
                            </el-form-item>
                            <!-- <el-form-item label="已入库数：" style="margin-left:15px">
                                <p v-for="(item,index) in props.row.productAttrValue" :key="index">{{item.enter_number}}</p>
                            </el-form-item> -->
                            <el-form-item label="库存：" style="margin-left:30px">
                                <p v-for="(item,index) in props.row.productAttrValue" :key="index">{{item.stock}}</p>
                            </el-form-item>
                            <el-form-item label="销量：" style="margin-left:30px">
                                <p v-for="(item,index) in props.row.productAttrValue" :key="index">{{item.sales}}</p>
                            </el-form-item>
                            
                            <el-form-item label="入库数：" style="margin-left:30px">
                                <p v-for="(item,index) in props.row.productAttrValue" :key="index">
                                    <el-input v-model="item.number" placeholder="入库数" size="mini" style="width:100px" oninput="this.value=this.value.toString().match(/^\d+(?:\d{0})?/)" @change="saveEnterStore(item)"></el-input>
                                </p>
                            </el-form-item>
                        </el-form>
                    </template>
                    </el-table-column>
                <el-table-column prop="name" label="商品名称"  show-overflow-tooltip></el-table-column>
                <el-table-column prop="category.cate_name" label="商品分类"  show-overflow-tooltip></el-table-column>
                <el-table-column prop="total_number" label="总入库数"  show-overflow-tooltip></el-table-column>
                <el-table-column prop="total_sales" label="总出库数"  show-overflow-tooltip></el-table-column>
                <el-table-column label="商品主图" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <el-image class="tableImage" :src="domain + scope.row.image" :preview-src-list="[domain + scope.row.image]"></el-image>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div class="paging">
                <el-pagination @current-change="currentChange" @size-change="sizeChange" :current-page="curr" :page-size="row" background layout="total, sizes, prev, pager, next, jumper" :total="count"></el-pagination>
            </div>
        </div>
    </div>
</template>
<script>
    import {housePlatformAddApi,housePlatformSaveApi}  from '@/api/house.js';
    import {shopTypeListApi}  from '@/api/store.js';
    export default {
        data() {
            return {
                select:{},
                list:[],
                selectData:{
                    sortData:[],
                    zsortData:[]
                },
                expands: [],

                title:'新建入库',
                curr: 1, //页码
                row: 10, //每页条数
                pages: 1, //总页数
                count: 0, //总条数
            }
        },
        mounted() {
            this.getList();
            this.getType();
        },
        methods: {
            //获取商品入库列表
            getList: function(){
                housePlatformAddApi({
                    curr:this.curr,
                    row:this.row,
                    ...this.select
                }).then(response=>{
                    this.list = response.list;
                    this.curr = response.curr;
                    this.pages = response.pages;
                    this.count = response.count;
                    if(response.list.length > -1){
                        this.toggleExpansion(response.list[0]);
                    }
                })
            },
            //获取商品类型
            getType:function(){
                shopTypeListApi().then(response=>{
                    this.selectData.sortData = response;
                    this.selectData.zsortData = response;
                })
            },
            //选择父级分类
            changeType: function(event){
                let _this = this;
                this.selectData.zsortData = [];
                this.expands = []; 
                for (var val of this.selectData.sortData) {
                    if(val.id == event){
                        this.selectData.zsortData.push(val)
                    }
                }
                
                this.getList();

                //若清空父类则恢复子类下拉数据
                setTimeout(function(){
                    if(!event){
                        for (var val of _this.selectData.sortData) {
                            _this.selectData.zsortData.push(val)
                        }
                    }
                },200)  
            },

            //选择子分类
            changeSrot:function(event){
                this.select.cate_id = event;
                this.getList();
            },
            
            //入库只刷新数据
            refreshData: function(){
                housePlatformAddApi({
                    curr:this.curr,
                    row:this.row,
                    ...this.select
                }).then(response=>{
                    this.list = response.list;
                    this.curr = response.curr;
                    this.pages = response.pages;
                    this.count = response.count;
                })
            },

            //保存入库
            saveEnterStore: function(item){
                if(item.number > item.total_stock){
                    this.$message({
                        message: '入库数大于总库存禁止入库！',
                        type: 'error'
                    });
                    return;
                }
                housePlatformSaveApi({
                    attr_id:item.id,
                    number:item.number,
                    product_id:item.product_id,
                    code:item.code
                }).then(()=>{
                    this.$message({
                        message: '入库成功！',
                        type: 'success'
                    });
                    this.refreshData();
                }) 
            },

            getRowKeys(row) {
                return row.id;
            },

            //点击当前行
            toggleExpansion(row) {
                let [expandsNum] = this.expands;
                if (expandsNum == row.id) {
                    this.expands = [];
                    return false;
                }

                this.expands = [];
                this.expands.push(row.id);
            },
            
            //翻页
            currentChange: function(curr) {
                this.curr = curr;
                this.getList();
            },
            //改变每页条数
            sizeChange: function(row) {
                this.row = row;
                this.getList();
            },
            goBack: function(){
                this.$router.go(-1)
            }
        },
    }
</script>
<style lang="less" scoped>
	.el-page-header__left {
		.el-icon-back{
			display: none;
		}
	}
</style>